import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import ReactDiffViewer from "react-diff-viewer";
import stringifyPretty from "json-stringify-pretty-compact";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";

const JournalsDiffPreviewModal = ({ handleClose, selectedDiffPreview }) => {
  const isOpen = !!selectedDiffPreview;

  const formattedExisting = stringifyPretty(selectedDiffPreview?.existing, { maxLength: 80 });
  const formattedPreview = stringifyPretty(selectedDiffPreview?.preview, { maxLength: 80 });

  return (
    <Dialog maxWidth="xl" fullWidth onClose={handleClose} open={isOpen}>
      <DialogTitle id="alert-dialog-title">Journal difference review</DialogTitle>
      <DialogContent dividers>
        <MDBox py={3}>
          {selectedDiffPreview && (
            <Grid item xs={12} lg={10}>
              <MDBox mb={3}>
                <MDBox mb={1} p={3} className="diff_viewer_container">
                  <ReactDiffViewer
                    oldValue={formattedExisting}
                    newValue={formattedPreview}
                    splitView
                    hideLineNumbers
                  />
                </MDBox>
              </MDBox>
            </Grid>
          )}
        </MDBox>
      </DialogContent>
      <DialogActions>
        <MDButton variant="gradient" color="info" size="medium" onClick={handleClose}>
          Close
        </MDButton>
      </DialogActions>
    </Dialog>
  );
};

export default JournalsDiffPreviewModal;
