import React, { useState } from "react";
import { Grid, Card, TextField } from "@mui/material";
import ReactDiffViewer from "react-diff-viewer";
import stringifyPretty from "json-stringify-pretty-compact";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Spinner from "components/Spinner/Spinner";

import { getEvent, removeValueFromEvent } from "helpers/events";
import { showErrorToast, showSuccessToast } from "helpers/helpers";
import JsonData from "layouts/pages/details/settings/components/JsonData";

function GDPRReplace() {
  const [uuid, setUuid] = useState("");
  const [eventData, setEventData] = useState(null);
  const [valueToReplace, setValueToReplace] = useState("");
  const [reviewedEventData, setReviewedEventData] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const fetchEventByUuid = async () => {
    setValueToReplace("");
    setEventData(null);
    setReviewedEventData(null);
    try {
      if (!uuid) {
        return;
      }
      setIsLoading(true);
      const response = await getEvent(uuid);
      setEventData(response.data);
    } catch (e) {
      showErrorToast("Event with this UUID does not exist");
    } finally {
      setIsLoading(false);
    }
  };

  const handleReview = () => {
    const updateObjectRecursively = (obj) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const key in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(key)) {
          if (typeof obj[key] === "object" && obj[key] !== null) {
            updateObjectRecursively(obj[key]);
          } else if (obj[key] === valueToReplace) {
            // eslint-disable-next-line no-param-reassign
            obj[key] = "";
          }
        }
      }
    };

    const updatedEventData = JSON.parse(JSON.stringify(eventData?.bronze));

    updateObjectRecursively(updatedEventData);
    setReviewedEventData(updatedEventData);
  };

  const handleReset = () => {
    setUuid("");
    setValueToReplace("");
    setEventData(null);
    setReviewedEventData(null);
  };

  const handleCommit = async () => {
    try {
      setIsLoading(true);
      await removeValueFromEvent(uuid, eventData?.type, valueToReplace);
      handleReset();
      showSuccessToast();
    } catch (error) {
      showErrorToast();
    } finally {
      setIsLoading(false);
    }
  };

  const formattedExisting = stringifyPretty(eventData?.bronze, { maxLength: 80 });
  const formattedPreview = stringifyPretty(reviewedEventData, { maxLength: 80 });

  const showFetchedEvent = !reviewedEventData && eventData;
  const showDiffView = reviewedEventData && eventData;
  const showSubmitButtons = reviewedEventData && eventData;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} md={6}>
            <Card>
              <MDBox p={3}>
                <MDTypography variant="h6" gutterBottom>
                  Event UUID
                </MDTypography>
                <TextField
                  placeholder="UUID"
                  value={uuid}
                  onChange={(e) => setUuid(e.target.value)}
                  sx={{ width: "70%" }}
                />
                <MDButton
                  onClick={fetchEventByUuid}
                  variant="gradient"
                  color="info"
                  sx={{ ml: 2, mt: 0.4 }}
                >
                  Load Event
                </MDButton>
              </MDBox>
              {eventData && (
                <MDBox p={3}>
                  <MDTypography variant="h6" gutterBottom>
                    Replacement text
                  </MDTypography>
                  <TextField
                    placeholder="Replacement text"
                    value={valueToReplace}
                    onChange={(e) => setValueToReplace(e.target.value)}
                    sx={{ width: "70%" }}
                  />
                  <MDButton
                    onClick={handleReview}
                    variant="gradient"
                    color="info"
                    sx={{ ml: 2, mt: 0.4, width: "120px" }}
                  >
                    Review
                  </MDButton>
                </MDBox>
              )}
              {showSubmitButtons && (
                <MDBox p={3} sx={{ display: "flex", justifyContent: "center" }}>
                  <MDButton
                    onClick={handleCommit}
                    variant="gradient"
                    color="success"
                    sx={{ mr: 4 }}
                  >
                    Commit
                  </MDButton>
                  <MDButton onClick={handleReset} variant="gradient" color="error">
                    Reset
                  </MDButton>
                </MDBox>
              )}
            </Card>
          </Grid>
          <Grid item md={12}>
            {isLoading && <Spinner />}
            {!isLoading && showFetchedEvent && (
              <Card>
                <MDBox p={2}>
                  <MDTypography variant="h6" gutterBottom>
                    Event to modify:
                  </MDTypography>
                  <JsonData data={eventData?.bronze} />
                </MDBox>
              </Card>
            )}
            {!isLoading && showDiffView && (
              <Card>
                <MDBox p={2}>
                  <MDTypography variant="h6" gutterBottom>
                    Event Data:
                  </MDTypography>
                  <ReactDiffViewer
                    oldValue={formattedExisting}
                    newValue={formattedPreview}
                    splitView
                    hideLineNumbers
                  />
                </MDBox>
              </Card>
            )}
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default GDPRReplace;
