import "./ErrorMessage.css";


const ErrorMessage = ({errorMessage}) => (
  <div className="error_container">
    <h5 className="error_message">
        {errorMessage || "Something went wrong, please try again"}
    </h5>
  </div>
);

export default ErrorMessage;