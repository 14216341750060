import React, { useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import "./Pagination.css";


const Pagination = ({ handlePageClick, pageCount, currentPage }) => {
  const paginationClassname = pageCount > 1 ? "pagination" : "pagination hide";

  const scrollToActivePage = () => {
    const activePage = document.querySelector('.pagination .active');
    const rect = activePage.getBoundingClientRect();
    const paginationContainer = document.querySelector('.pagination_container');
    document.querySelector('.pagination').scrollLeft = rect.left - paginationContainer.offsetWidth;
  };

  useEffect(() => {
    scrollToActivePage();
  }, []);


  return (
    <div className="pagination_container">
      <ReactPaginate
        previousLabel='chevron_left'
        previousClassName={currentPage + 1 > 1 ? 'previous material-icons-round notranslate MuiIcon-root MuiIcon-fontSizeInherit' : 'hide'}
        nextClassName={currentPage + 1 < pageCount ? 'next material-icons-round notranslate MuiIcon-root MuiIcon-fontSizeInherit' : 'hide'}
        nextLabel={'chevron_right'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        forcePage={currentPage}
        pageClassName={'li_styles'}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        onPageChange={handlePageClick}
        containerClassName={paginationClassname}
        activeClassName={'active'}
      />
    </div>
  );
};

export default Pagination;
