import React, { useState, useEffect, useRef } from "react";
import { Grid, Card, Autocomplete, Tooltip, IconButton } from "@mui/material";
import ArticleIcon from "@mui/icons-material/Article";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "components/table/Table";
import Spinner from "components/Spinner/Spinner";
import Pagination from "components/pagination/Pagination";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import NoContentMessage from "components/NoContentMessage/NoContentMessage";
import { transformDate } from "helpers/helpers";
import { getEvents } from "helpers/events";
import MDInput from "components/MDInput";
import { useMaterialUIController } from "context";
import EventReviewModal from "components/modal/EventReviewModal";
import { itemsPerPage, itemsTotal } from "../../constants";

function EventsList({ location, history }) {
  const searchValue = location?.state?.searchValue ?? "";
  const isAdvancedSearch = location?.state?.isAdvancedSearch ?? false;

  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [offset, setOffset] = useState(1);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Something went wrong, please try again");

  const [filterByEventType, setFilterByEventType] = useState("");
  const [selectedEventId, setSelectedEventId] = useState(null);

  const [controller] = useMaterialUIController();
  const { startDate, endDate } = controller;

  const columns = [
    {
      Header: "Submission ID",
      id: "submission_id",
      Cell: ({ row }) => (
        <span className="route_condition_label">{row.original.submission_id || ""}</span>
      ),
    },
    {
      Header: "Manuscript ID",
      id: "manuscript_id",
      Cell: ({ row }) => (
        <span className="route_condition_label">{row.original.manuscript_id || ""}</span>
      ),
    },
    {
      Header: "DOI",
      id: "doi",
      Cell: ({ row }) => <span className="route_condition_label">{row.original.doi || ""}</span>,
    },
    {
      Header: "Event type",
      id: "event_type",
      Cell: ({ row }) => (
        <span className="route_condition_label">{row.original.event_type || ""}</span>
      ),
    },
    {
      Header: "Timestamp",
      id: "timestamp",
      Cell: ({ row }) => (
        <span className="route_condition_label">{transformDate(row.original.timestamp, true)}</span>
      ),
    },
    {
      Header: "Related",
      id: "Related",
      Cell: ({ row }) => {
        const articleId = row.original.article_id;
        return articleId ? (
          <span className="route_condition_label">
            <Tooltip title="Related events">
              <IconButton onClick={() => history.push(`/article/${articleId}/events`)}>
                <ArticleIcon />
              </IconButton>
            </Tooltip>
          </span>
        ) : null;
      },
    },
  ];

  const fetchEvents = async (resetPageCount = false) => {
    setLoading(true);
    setError(false);
    if (resetPageCount) {
      setCurrentPage(0);
    }
    try {
      const res = await getEvents(
        offset,
        itemsPerPage,
        filterByEventType,
        startDate ?? "",
        endDate ?? "",
        searchValue ?? "",
        isAdvancedSearch
      );
      setEvents(res.data.events || []);
      setPageCount(Math.ceil((res.data.total || itemsTotal) / itemsPerPage));
    } catch (e) {
      setError(true);
      setErrorMessage(e.message);
    } finally {
      setLoading(false);
    }
  };

  const isInitialLoad = useRef(true);

  useEffect(() => {
    const fetchAndSetEvents = async () => {
      if (isInitialLoad.current) {
        await fetchEvents(false);
        isInitialLoad.current = false;
      } else {
        setCurrentPage(0);
        setOffset(1);
        await fetchEvents(true);
      }
    };

    fetchAndSetEvents();
  }, [filterByEventType, startDate, endDate, searchValue, isAdvancedSearch]);

  useEffect(() => {
    if (!isInitialLoad.current) {
      fetchEvents(false);
    }
  }, [offset]);

  const handleFilterChange = (_, option) => {
    if (option) {
      setFilterByEventType(option.value);
    } else {
      setFilterByEventType("");
    }
  };

  const handleEventReview = (eventId) => {
    setSelectedEventId(eventId);
  };

  const handleModalClose = () => {
    setSelectedEventId(null);
  };

  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage);
    setOffset(selectedPage * itemsPerPage + 1);
  };

  const eventTypes = [
    { label: "Censhare", value: "censhare" },
    { label: "EM", value: "em" },
    { label: "S1", value: "s1" },
    { label: "TC", value: "tc" },
    { label: "CCC", value: "ccc" },
  ];
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <EventReviewModal handleClose={handleModalClose} selectedEventId={selectedEventId} />

      <MDBox my={3}>
        <Grid container justifyContent="flex-end">
          <MDBox mb={3}>
            <Autocomplete
              onChange={handleFilterChange}
              options={eventTypes}
              getOptionLabel={(option) => (option ? option.label : "")}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  style={{ minWidth: "200px" }}
                  placeholder="Filter by event type"
                  variant="standard"
                />
              )}
            />
          </MDBox>
        </Grid>
        {loading && <Spinner />}
        {!loading && error && <ErrorMessage errorMessage={errorMessage} />}
        {!loading && !error && events.length > 0 && (
          <Card>
            <DataTable
              data={events}
              columns={columns}
              onRowClick={handleEventReview}
              param="id"
              hoverable
              cursorPointer
            />
            <Pagination
              handlePageClick={handlePageClick}
              pageCount={pageCount}
              currentPage={currentPage}
            />
          </Card>
        )}
        {!loading && !error && events.length === 0 && <NoContentMessage message="No events" />}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EventsList;
