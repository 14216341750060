import Ajv from 'ajv';

const localSettingsSchema = {
  type: 'object',
  properties: {
    scope: {
      type: 'array',
      items: {
        type: 'object',
      },
      default: [],
      nullable: true,
    },
    sorting: {
      type: 'string', default: '', nullable: true,
    },
    startDate: {
      type: 'string', default: '', nullable: true,
    },
    endDate: {
      type: 'string', default: '', nullable: true,
    },
    articleAttribute: {
      type: 'array',
      items: { type: 'string' },
      default: [],
      nullable: true,
    },
    tctype: {
      type: 'array',
      items: { type: 'string' },
      default: [],
      nullable: true,
    },
  },
};

export function validateSettingsSchema(localSettings) {
  const ajv = new Ajv();
  const isValid = ajv.validate(localSettingsSchema, localSettings);

  if (!isValid) {
    console.error('Invalid settings data:', ajv.errors);
  }

  return isValid;
}