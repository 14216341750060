import React from "react";
import { transformDate } from "../../helpers/helpers";

function useColumnsPerConnectorConfig(connectorType) {
  return {
    s1: React.useMemo(
      () => [
        {
          Header: "created",
          id: "timestamp",
          Cell: ({ row }) => (
            <span className="route_condition_label">
              {transformDate(row.original.timestamp, true)}
            </span>
          ),
        },
        {
          Header: "subscription_type",
          id: "subscription_type",
          Cell: ({ row }) => (
            <span className="route_condition_label">{row.original?.subscription_type || "-"}</span>
          ),
        },
        {
          Header: "decision_type",
          id: "decision_type",
          Cell: ({ row }) => (
            <span className="route_condition_label">{row.original?.decision_type || "-"}</span>
          ),
        },
        {
          Header: "system_event_name",
          id: "system_event_name",
          Cell: ({ row }) => (
            <span className="route_condition_label">{row.original?.system_event_name || "-"}</span>
          ),
        },
        {
          Header: "subscription_name",
          id: "subscription_name",
          Cell: ({ row }) => (
            <span className="route_condition_label">{row.original?.subscription_name || "-"}</span>
          ),
        },
        {
          Header: "task_status",
          id: "task_status",
          Cell: ({ row }) => (
            <span className="route_condition_label">{row.original?.task_status || "-"}</span>
          ),
        },
        {
          Header: "site_name",
          id: "site_name",
          Cell: ({ row }) => (
            <span className="route_condition_label">{row.original?.site_name || "-"}</span>
          ),
        },
        {
          Header: "submission_id",
          id: "submission_id",
          Cell: ({ row }) => (
            <span className="route_condition_label">{row.original?.submission_id || "-"}</span>
          ),
        },
      ],
      [connectorType]
    ),
    em: React.useMemo(
      () => [
        {
          Header: "created",
          id: "timestamp",
          Cell: ({ row }) => (
            <span className="route_condition_label">
              {transformDate(row.original.timestamp, true)}
            </span>
          ),
        },
        {
          Header: "subscription_name",
          id: "subscription_name",
          Cell: ({ row }) => (
            <span className="route_condition_label">{row.original?.subscription_name || "-"}</span>
          ),
        },
        {
          Header: "subscription_type",
          id: "subscription_type",
          Cell: ({ row }) => (
            <span className="route_condition_label">{row.original?.subscription_type || "-"}</span>
          ),
        },
        {
          Header: "decision_type",
          id: "decision_type",
          Cell: ({ row }) => (
            <span className="route_condition_label">{row.original?.decision_type || "-"}</span>
          ),
        },
        {
          Header: "document_id",
          id: "document_id",
          Cell: ({ row }) => (
            <span className="route_condition_label">{row.original?.document_id || "-"}</span>
          ),
        },
        {
          Header: "site_name",
          id: "site_name",
          Cell: ({ row }) => (
            <span className="route_condition_label">{row.original?.site_name || "-"}</span>
          ),
        },
        {
          Header: "submission_id",
          id: "submission_id",
          Cell: ({ row }) => (
            <span className="route_condition_label">{row.original?.submission_id || "-"}</span>
          ),
        },
      ],
      [connectorType]
    ),
    tc: React.useMemo(
      () => [
        {
          Header: "created",
          id: "timestamp",
          Cell: ({ row }) => (
            <span className="route_condition_label">
              {transformDate(row.original.timestamp, true)}
            </span>
          ),
        },
        {
          Header: "manuscript_id",
          id: "manuscript_id",
          Cell: ({ row }) => (
            <span className="route_condition_label">{row.original?.manuscript_id || row.original?.article_id_manuscript || "-"}</span>
          ),
        },
        {
          Header: "journal_doi",
          id: "journal_doi",
          Cell: ({ row }) => (
            <span className="route_condition_label">{row.original?.journal_doi || row.original?.journal_id_doi || "-"}</span>
          ),
        },
        {
          Header: "doi",
          id: "doi",
          Cell: ({ row }) => (
            <span className="route_condition_label">{row.original?.doi || row.original?.article_id_doi || "-"}</span>
          ),
        },
      ],
      [connectorType]
    ),
    censhare: React.useMemo(
      () => [
        {
          Header: "created",
          id: "timestamp",
          Cell: ({ row }) => (
            <span className="route_condition_label">
              {transformDate(row.original.timestamp, true)}
            </span>
          ),
        },
        {
          Header: "type",
          id: "type",
          Cell: ({ row }) => (
            <span className="route_condition_label">{row.original?.type || "-"}</span>
          ),
        },
        {
          Header: "doi",
          id: "doi",
          Cell: ({ row }) => (
            <span className="route_condition_label">{row.original?.doi || "-"}</span>
          ),
        },
        {
          Header: "journal_doi",
          id: "journal_doi",
          Cell: ({ row }) => (
            <span className="route_condition_label">{row.original?.journal_doi || "-"}</span>
          ),
        },
        {
          Header: "manuscript_id",
          id: "manuscript_id",
          Cell: ({ row }) => (
            <span className="route_condition_label">{row.original?.manuscript_id || "-"}</span>
          ),
        },
      ],
      [connectorType]
    ),
    ccc: React.useMemo(
      () => [
        {
          Header: "created",
          id: "timestamp",
          Cell: ({ row }) => (
            <span className="route_condition_label">
              {transformDate(row.original.timestamp, true)}
            </span>
          ),
        },
        {
          Header: "doi",
          id: "doi",
          Cell: ({ row }) => (
            <span className="route_condition_label">{row.original?.doi || "-"}</span>
          ),
        },
        {
          Header: "manuscript_id",
          id: "manuscript_id",
          Cell: ({ row }) => (
            <span className="route_condition_label">{row.original?.manuscript_id || "-"}</span>
          ),
        },
      ],
      [connectorType]
    ),
  };
}

export default useColumnsPerConnectorConfig;
