import { loginWithAuthorizationCode } from 'helpers/auth'
import Spinner from 'components/Spinner/Spinner'
import { token } from '../../../constants'
import 'react-toastify/dist/ReactToastify.css'
import { setItemToLocalStorage, showErrorToast } from 'helpers/helpers'
import { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'

const handleError = () => {
    showErrorToast("Error while login");
};

export function AuthorizationCodeFlow() {
    const [loading, setLoading] = useState(true)

    useEffect(
        () => {
            const url = new URL(window.location.href);
            const code = url.searchParams.get("code");
            if (!code) {
                setLoading(false);
                return;
            }
            loginWithAuthorizationCode(code)
                .then((response) => {
                    if (response?.status === 200) {
                        setItemToLocalStorage(token, response.data.token);
                    } else {
                        handleError();
                    }
                })
                .catch(handleError)
                .finally(() => {
                    setLoading(false);
                });
        },
        []
    );

    return (
        <div>{
            loading ?
                <Spinner />
                :
                <Redirect to="/dashboard" />
        }</div>
    )
}