import { axiosInstanceWithTimeout, axiosInstance } from "./axiosSetup";
import { handleErrors } from "./handleErrors";

export async function getEvents(
  offset,
  limit,
  filter = "",
  from = "",
  to = "",
  searchValue = "",
  isAdvancedSearch = false
) {
  return axiosInstanceWithTimeout
    .get(
      `medallion/silver/events?offset=${offset}&limit=${limit}&connector=${filter}&from=${from}&to=${to}&search=${searchValue}&is_advanced_search=${isAdvancedSearch}`
    )
    .catch(handleErrors);
}

export async function getEvent(id) {
  return axiosInstance.get(`medallion/event/${id}`).catch(handleErrors);
}

export async function getEventsPerJournal(offset, perPage, sortBy, sortDir) {
  return axiosInstance
    .get(
      `admin/events/journals?startrow=${offset}&maxrows=${perPage}&orderby=${sortBy}&orderdir=${sortDir}`
    )
    .catch(handleErrors);
}

export async function getEventTypes(offset, limit) {
  return axiosInstanceWithTimeout
    .get(`medallion/bronze/event-types?offset=${offset}&limit=${limit}`)
    .catch(handleErrors);
}

export async function updateEventType(key, data) {
  return axiosInstance.patch(`medallion/bronze/event-types/${key}`, data).catch(handleErrors);
}

export async function getArticleEvents(articleId, offset, limit) {
  return axiosInstance
    .get(`medallion/silver/articles/${articleId}/events?offset=${offset}&limit=${limit}`)
    .catch(handleErrors);
}

export async function removeValueFromEvent(eventId, connector, value) {
  return axiosInstance
    .post(`medallion/event/${eventId}/replacement?connector=${connector}`, {
      target_text: value,
    })
    .catch(handleErrors);
}

export async function getEventsByDOI(doi) {
  return axiosInstanceWithTimeout
    .get(`medallion/silver/events?doi=${doi}&limit=${Number.MAX_SAFE_INTEGER}&source=tc&source=censhare`)
    .catch(handleErrors);
}

export async function deleteEvents(events) {

  return axiosInstance
    .delete(`medallion/events`, { data: { events }})
    .catch(handleErrors);
}
