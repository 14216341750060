import Card from "@mui/material/Card";
import { withFormik } from 'formik';
import { ToastContainer } from 'react-toastify';
import { useState, useCallback } from "react";
import MDBox from "components/MDBox";
import MDButton from 'components/MDButton'
import MDTypography from "components/MDTypography";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import bgImage from "assets/images/bg-sign-in-cover.jpeg";
import LoginForm from "components/LoginForm";
import { getRedirectServerUrl, logIn } from "helpers/auth";
import Spinner from "components/Spinner/Spinner";
import { showErrorToast } from "helpers/helpers";
import { ENVIRONMENT, token } from "../../../constants";
import 'react-toastify/dist/ReactToastify.css';

const LoginMessage = ({ isDefaultLogin }) => (
  <MDTypography display="block" variant="button" color="white" my={1}>
    {isDefaultLogin ? 'Click to login with Active Directory' : 'Enter your email and password to Login'}
  </MDTypography>
);

const LoginFormContainer = ({ isSubmitting, handleSubmit }) => isSubmitting ? (
  <Spinner />
) : (
  <LoginForm handleSubmit={handleSubmit} />
);

const isDevelopmentEnvironment = ENVIRONMENT === "development"

function Login({ handleSubmit, isSubmitting }) {
  const [isDefaultLogin, setIsDefaultLogin] = useState(true);

  const handleToggleLoginType = useCallback(() => {
    setIsDefaultLogin(prev => !prev);
  }, []);

  const handleRedirectToAuthorizationServer = useCallback(async (event) => {
    event.preventDefault();
    window.location.href = await getRedirectServerUrl();
  }, []);

  return (
    <CoverLayout image={bgImage}>
      <ToastContainer />
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Login
          </MDTypography>
          <LoginMessage isDefaultLogin={isDefaultLogin} />
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          {isDefaultLogin ? (
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth type="button" onClick={handleRedirectToAuthorizationServer}>
                Login with Thieme account
              </MDButton>
            </MDBox>
          ) : (
            <LoginFormContainer isSubmitting={isSubmitting} handleSubmit={handleSubmit} />
          )}
          {isDevelopmentEnvironment && (
            <MDButton fullWidth onClick={handleToggleLoginType}>
              {isDefaultLogin ? 'Login with ESS account' : 'Login with Thieme account'}
            </MDButton>
          )}
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

const mapPropsToValues = () => ({
  email: "",
  password: "",
});

const handleSubmit = (value, { props, setSubmitting }) => {
  logIn(value)
    .then(response => {
      localStorage.setItem(token, response.data.token);
      props.history.push("/dashboard");
      window.location.reload();
    })
    .catch(() => {
      setSubmitting(false);
      showErrorToast("User does not exist");
    })
};

export default withFormik({ handleSubmit, mapPropsToValues })(Login);