import { axiosInstance } from "./axiosSetup";
import { handleErrors } from "./handleErrors";

export async function getBronzeData(
  connectorType,
  offset,
  itemsPerPage,
  sortBy,
  sortDir,
  dateFrom = "",
  dateTo = ""
) {
  const queryParametersString = `connector=${connectorType}&offset=${offset}&limit=${itemsPerPage}&sort_by=${sortBy}&sort_dir=${sortDir}&from=${dateFrom}&to=${dateTo}`;
  return axiosInstance.get(`medallion/bronze?${queryParametersString}`).catch(handleErrors);
}

export async function getSilverData(
  connectorType,
  offset,
  itemsPerPage,
  sortBy,
  sortDir,
  dateFrom = "",
  dateTo = ""
) {
  const queryParametersString = `connector=${connectorType}&offset=${offset}&limit=${itemsPerPage}&sort_by=${sortBy}&sort_dir=${sortDir}&from=${dateFrom}&to=${dateTo}`;
  return axiosInstance.get(`medallion/silver?${queryParametersString}`).catch(handleErrors);
}
