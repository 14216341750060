import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useMaterialUIController } from "context";
import EventReviewModal from "components/modal/EventReviewModal";
import { getSilverData } from "../../helpers/medallion";
import Spinner from "../../components/Spinner/Spinner";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import MDBox from "../../components/MDBox";
import DataTable from "../../components/table/Table";
import Pagination from "../../components/pagination/Pagination";
import { itemsPerPage, itemsTotal } from "../../constants";
import useColumnsPerConnectorConfig from "./SilverLevelColumns";

function SilverLevelData({ match }) {
  const connectorType = match?.params?.type;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [sortBy, setSortBy] = useState("timestamp");
  const [sortDir, setSortDir] = useState("desc");
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [offset, setOffset] = useState(1);

  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const columnsConnectorMap = useColumnsPerConnectorConfig(connectorType);

  const [controller] = useMaterialUIController();
  const { startDate, endDate } = controller;

  const fetchData = async (newOffset = offset) => {
    if (!connectorType) return;

    setLoading(true);
    try {
      const response = await getSilverData(
        connectorType,
        newOffset,
        itemsPerPage,
        sortBy,
        sortDir,
        startDate ?? "",
        endDate ?? ""
      );
      setData(response.data.events);
      setPageCount(Math.ceil((response.data.total || itemsTotal) / itemsPerPage));
      setError(false);
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setCurrentPage(0)
    fetchData(1);
  }, [connectorType])

  useEffect(fetchData, [offset, sortBy, sortDir, startDate, endDate]);

  const handleModalClose = () => {
    setSelectedEventId(null);
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    const pageItems = selectedPage * itemsPerPage;
    setCurrentPage(selectedPage);
    setOffset(pageItems + 1);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <EventReviewModal handleClose={handleModalClose} selectedEventId={selectedEventId} />
      {loading && <Spinner />}
      {!loading && error && <ErrorMessage />}
      {!loading && !error && connectorType && (
        <MDBox>
          <Card>
            <DataTable
              data={data}
              columns={columnsConnectorMap[connectorType]}
              param="uuid"
              sortBy={sortBy}
              sortDir={sortDir}
              setSortBy={setSortBy}
              setSortDir={setSortDir}
              hoverable
              cursorPointer
              onRowClick={setSelectedEventId}
            />
            <Pagination
              handlePageClick={handlePageClick}
              pageCount={pageCount}
              currentPage={currentPage}
            />
          </Card>
        </MDBox>
      )}
      <Footer />
    </DashboardLayout>
  );
}

export default SilverLevelData;
