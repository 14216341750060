/* eslint-disable no-console */
import { token } from "../constants";
import { axiosAuthInstance } from "./axiosSetup";

export async function logIn(value) {
  return axiosAuthInstance.post("/authorize", value).catch((error) => console.log(error));
}

export async function loginWithAuthorizationCode(code) {
  return axiosAuthInstance.post("/authorize/code", { code }).catch((error) => console.log(error));
}

export async function getRedirectServerUrl() {
  return axiosAuthInstance
    .get("/authorize/redirect_uri")
    .then((response) => response.data.url)
    .catch((error) => console.log(error));
}

export const logOut = () => {
  localStorage.removeItem(token);
  window.location.reload();
};
