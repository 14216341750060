import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import MDButton from 'components/MDButton';
import JsonData from 'layouts/pages/details/settings/components/JsonData';

const JournalDetailsModal = ({ onClose, journal }) => {
    const isOpen = !!journal;
    const handleClose = () => {
        onClose();
    }

    return (
        <Dialog
            maxWidth="xl"
            onClose={handleClose}
            open={isOpen}
        >
            <DialogTitle id="alert-dialog-title" >
                Journal review
            </DialogTitle>
            <DialogContent>
                <JsonData data={journal} />
            </DialogContent>
            <DialogActions>
                <MDButton className="search_button" variant="gradient" color="info" size="medium" onClick={handleClose}>
                    Close
                </MDButton>
            </DialogActions>

        </Dialog>
    )
}
export default JournalDetailsModal