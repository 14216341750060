import React, { useState, useEffect, useMemo } from "react";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "components/table/Table";
import Spinner from "components/Spinner/Spinner";
import { getJournals } from "helpers/journals";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import NoContentMessage from "components/NoContentMessage/NoContentMessage";
import Pagination from "components/pagination/Pagination";
import { itemsPerPage, itemsTotal } from "../../constants";
import JournalDetailsModal from "./components/JournalDetailsModal";

function JournalsList() {
  const [journals, setJournals] = useState([]);
  const [loading, setLoading] = useState(false);

  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [offset, setOffset] = useState(1);

  const [error, setError] = useState(false);

  const [selectedJournalId, setSelectedJournalId] = useState(null);

  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage);
    setOffset(selectedPage * itemsPerPage + 1);
  };

  const columns = [
    {
      Header: "title",
      id: "title",
      Cell: ({ row }) => <span>{row.original.journal_title ?? "-"}</span>,
    },
    {
      Header: "doi",
      id: "doi",
      Cell: ({ row }) => <span>{row.original.doi || "-"}</span>,
    },
    {
      Header: "journal_status",
      id: "journal_status",
      Cell: ({ row }) => <span>{row.original.journal_status || "-"}</span>,
    },
  ];

  const fetchJournals = async () => {
    setLoading(true);
    try {
      const response = await getJournals(offset, itemsPerPage);
      setJournals(response?.data?.results ?? []);
      setPageCount(Math.ceil((response.data.total || itemsTotal) / itemsPerPage));
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchJournals();
  }, [offset]);

  const selectedJournal = useMemo(() => journals.find(x => x.doi === selectedJournalId), [selectedJournalId, journals])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {selectedJournal && (
        <JournalDetailsModal journal={selectedJournal} onClose={() => setSelectedJournalId(null)} />
      )}
      <MDBox my={3}>
        {loading && <Spinner />}
        {!loading && error && <ErrorMessage />}
        {!loading && !error && journals.length > 0 && (
          <>
            <DataTable
              param="doi"
              onRowClick={setSelectedJournalId}
              data={journals}
              columns={columns}
              hoverable
              cursorPointer
            />
            <Pagination
              handlePageClick={handlePageClick}
              pageCount={pageCount}
              currentPage={currentPage}
            />
          </>
        )}
        {!loading && !error && journals.length === 0 && <NoContentMessage message="No events" />}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default JournalsList;
