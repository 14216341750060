import React from "react";
import { useTable } from "react-table";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import MDBox from "components/MDBox";
import DataTableHeadCell from "./DataTableHeadCell";
import DataTableBodyCell from "./DataTableBodyCell";

const DataTable = ({
  data,
  columns,
  sortBy,
  sortDir,
  onRowClick,
  setSortBy,
  setSortDir,
  param,
  sortable = false,
  hoverable = false,
  cursorPointer = false,
  hideHeader = false,
  noBorder = false,
}) => {
  const tableInstance = useTable({ columns, data });

  const handleReadMessage = (row) => {
    const rowValue = param === "all" ? row.original : row.original[param];
    const itemId = row && row.original ? rowValue : null;

    if (onRowClick) {
      onRowClick(itemId);
    }
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  const sortData = (accessor) => {
    const sortType = sortDir === "asc" ? "desc" : "asc";
    setSortBy(accessor);
    setSortDir(sortType);
  };

  return (
    <TableContainer sx={{ boxShadow: "none" }}>
      <Table {...getTableProps()}>
        {!hideHeader && (
          <MDBox component="thead">
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <DataTableHeadCell
                    onClick={sortable ? () => sortData(column.id) : null}
                    {...column.getHeaderProps()}
                    key={column.id}
                    width={"auto"}
                    align={column.align ? column.align : "left"}
                    sorted={sortDir}
                    columnId={column.id}
                    sortBy={sortBy}
                    sortable={sortable}
                  >
                    {column.render("Header")}
                  </DataTableHeadCell>
                ))}
              </TableRow>
            ))}
          </MDBox>
        )}

        <TableBody {...getTableBodyProps()}>
          {rows.map((row, key) => {
            prepareRow(row);

            return (
              <TableRow
                hover={hoverable}
                sx={{ cursor: cursorPointer ? "pointer" : "default" }}
                onClick={() => handleReadMessage(row)}
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => (
                  <DataTableBodyCell
                    noBorder={noBorder}
                    align={cell.column.align ? cell.column.align : "left"}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </DataTableBodyCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DataTable;
