import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import { Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";


function StatusCell({ icon, color, status, comment = null, onClick }) {

  return (
    <MDBox display="flex" alignItems="center">
      <Tooltip title={comment || ""}>
        <MDBox mr={1}>
          <div className="error_icon_container">
            <Icon
              sx={{ fontWeight: "bold", cursor: "default" }}
              fontSize='small'
              onClick={onClick}
              color={color}
            >{icon}</Icon>
          </div>
        </MDBox>
      </Tooltip>
      <MDTypography variant="caption" fontWeight="medium" color="text" sx={{ lineHeight: 0 }}>
        {status}
      </MDTypography>
    </MDBox>
  );
}


StatusCell.propTypes = {
  icon: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

export default StatusCell;
