import React, { useState, useEffect } from "react";
import { Card } from "@mui/material";
import { useParams } from "react-router-dom";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "components/table/Table";
import Spinner from "components/Spinner/Spinner";
import Pagination from "components/pagination/Pagination";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import NoContentMessage from "components/NoContentMessage/NoContentMessage";
import { transformDate } from "helpers/helpers";
import { getArticleEvents } from "helpers/events";
import EventReviewModal from "components/modal/EventReviewModal";
import { itemsPerPage } from "../../constants";

function ArticleEventsList() {
  const { id: articleId } = useParams();

  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [offset, setOffset] = useState(1);
  const [error, setError] = useState(false);
  const errorMessage = "Something went wrong, please try again";

  const [selectedEventId, setSelectedEventId] = useState(null);

  const columns = [
    {
      Header: "Submission ID",
      id: "submission_id",
      Cell: ({ row }) => (
        <span className="route_condition_label">{row.original.submission_id || ""}</span>
      ),
    },
    {
      Header: "Manuscript ID",
      id: "manuscript_id",
      Cell: ({ row }) => (
        <span className="route_condition_label">{row.original.manuscript_id || ""}</span>
      ),
    },
    {
      Header: "DOI",
      id: "doi",
      Cell: ({ row }) => <span className="route_condition_label">{row.original.doi || ""}</span>,
    },
    {
      Header: "Event type",
      id: "event_type",
      Cell: ({ row }) => (
        <span className="route_condition_label">{row.original.event_type || ""}</span>
      ),
    },
    {
      Header: "Timestamp",
      id: "timestamp",
      Cell: ({ row }) => (
        <span className="route_condition_label">{transformDate(row.original.timestamp, true)}</span>
      ),
    },
  ];

  const handleEventReview = (eventId) => setSelectedEventId(eventId);

  const handleModalClose = () => setSelectedEventId(null);

  useEffect(() => {
    const fetchEvents = async () => {
      setLoading(true);
      setError(false);
      try {
        const response = await getArticleEvents(articleId, offset, itemsPerPage);
        setEvents(response?.data?.results ?? []);
        setPageCount(Math.ceil(response.data.total / itemsPerPage));
      } catch (e) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, [offset]);

  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage);
    setOffset(selectedPage * itemsPerPage + 1);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <EventReviewModal handleClose={handleModalClose} selectedEventId={selectedEventId} />
      <MDBox my={3}>
        {loading && <Spinner />}
        {!loading && error && <ErrorMessage errorMessage={errorMessage} />}
        {!loading && !error && events?.length > 0 && (
          <Card>
            <DataTable
              data={events}
              columns={columns}
              onRowClick={handleEventReview}
              param="uuid"
              hoverable
              cursorPointer
            />
            <Pagination
              handlePageClick={handlePageClick}
              pageCount={pageCount}
              currentPage={currentPage}
            />
          </Card>
        )}
        {!loading && !error && events.length === 0 && <NoContentMessage message="No events" />}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ArticleEventsList;
