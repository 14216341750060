import { Switch, Route, Redirect } from "react-router-dom";
import Login from "layouts/authentication/log-in/Login";
import EventsList from "layouts/events/EventsList";
import Report from "layouts/reports";
import JournalsList from "pages/journals/JournalsList";
import UsersList from "layouts/users/UsersList";
import EventsPerJournalList from "layouts/events/EventsPerJournalList";
import Dashboard from "layouts/dashboards/sales/Dashboard";
import SilverLevelData from "layouts/medallion/SilverLevel";
import JournalFilesList from "pages/journalsImport/JournalFilesList";
import JournalsDiffList from "pages/journalsImport/JournalsDiffList";
import EventTypesList from "pages/event-types/EventTypesList";
import ArticleEventsList from "pages/article-events/ArticleEventsList";
import GDPRReplace from "pages/gdpr-replace/GDPRReplace";
import DeleteDOI from "pages/delete-doi/DeleteDOI";
import { AuthorizationCodeFlow } from "./layouts/authentication/log-in/AuthorizationCode";
import BronzeLevelData from "./layouts/medallion/BronzeLevel";
import { token } from "./constants";

const PrivateRoute = (props) => {
  const authorized = localStorage.getItem(token);

  return authorized ? <Route {...props} /> : <Redirect to="/login" />;
};

const Routes = () => (
  <Switch>
    <Route path="/auth" component={AuthorizationCodeFlow} />
    <Route path="/login" component={Login} />
    <PrivateRoute exact path="/" component={Dashboard} />
    <PrivateRoute path="/dashboard" component={Dashboard} />
    <PrivateRoute path="/report/:id" component={Report} />
    <PrivateRoute path="/users" component={UsersList} />
    <PrivateRoute path="/journals" component={JournalsList} />
    <PrivateRoute path="/journals-import" component={JournalFilesList} />
    <PrivateRoute path="/journals-import-preview/:uuid" component={JournalsDiffList} />
    <PrivateRoute path="/events" component={EventsList} />
    <PrivateRoute path="/events-per-journal" component={EventsPerJournalList} />
    <PrivateRoute path="/bronze/:type" component={BronzeLevelData} />
    <PrivateRoute path="/silver/:type" component={SilverLevelData} />
    <PrivateRoute path="/event-types" component={EventTypesList} />
    <PrivateRoute path="/article/:id/events" component={ArticleEventsList} />
    <PrivateRoute path="/gdpr-replace/" component={GDPRReplace} />
    <PrivateRoute path="/delete-doi/" component={DeleteDOI} />
  </Switch>
);

export default Routes;
