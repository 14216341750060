import { axiosInstance } from "./axiosSetup";
import { handleErrors } from "./handleErrors";

export async function getJournals(offset, perPage) {
  return axiosInstance
    .get(`tracker/journals?startrow=${offset}&maxrows=${perPage}`)
    .catch(handleErrors);
}

export async function getFilesWithJournals() {
  return axiosInstance.get(`tracker/files/journals?limit=100`).catch(handleErrors);
}

export async function prepareJournalsFileForImport(uuid) {
  return axiosInstance.post(`tracker/files/process/${uuid}`).catch(handleErrors);
}

export async function getJournalsFileDiffPreview(limit, offset) {
  return axiosInstance
    .get(`tracker/files/preview?limit=${limit}&offset=${offset}`)
    .catch(handleErrors);
}

export async function importFileWithDiffChanges() {
  return axiosInstance.put(`tracker/files/application`).catch(handleErrors);
}
