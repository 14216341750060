import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";

const routes = [
  {
    type: "collapse",
    name: "User",
    key: "user",
    collapse: [
      {
        name: "Filter",
        key: "filter",
      },
      {
        name: "Log off",
        key: "logout",
        route: "/login",
      },
    ],
  },
  { type: "divider", key: "divider-0" },
  {
    type: "page",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    noCollapse: true,
  },
  {
    type: "page",
    icon: <Icon fontSize="medium">receipt_long</Icon>,
    name: "Events",
    key: "events",
    route: "/events",
    noCollapse: true,
  },
  {
    type: "collapse",
    icon: <Icon fontSize="medium">content_paste</Icon>,
    name: "Bronze Level Data",
    key: "bronze",
    collapse: [
      {
        name: "s1",
        key: "s1-bronze",
        route: "/bronze/s1",
      },
      {
        name: "em",
        key: "em-bronze",
        route: "/bronze/em",
      },
      {
        name: "tc",
        key: "tc-bronze",
        route: "/bronze/tc",
      },
      {
        name: "censhare",
        key: "censhare-bronze",
        route: "/bronze/censhare",
      },
      {
        name: "ccc",
        key: "ccc-bronze",
        route: "/bronze/ccc",
      },
    ],
  },
  {
    type: "collapse",
    name: "Silver Level Data",
    key: "silver",
    icon: <Icon fontSize="medium">content_paste</Icon>,
    collapse: [
      {
        name: "s1",
        key: "silver-s1",
        route: "/silver/s1",
      },
      {
        name: "em",
        key: "em-silver",
        route: "/silver/em",
      },
      {
        name: "tc",
        key: "tc-silver",
        route: "/silver/tc",
      },
      {
        name: "censhare",
        key: "censhare-silver",
        route: "/silver/censhare",
      },
      {
        name: "ccc",
        key: "ccc-silver",
        route: "/silver/ccc",
      },
    ],
  },
  {
    type: "collapse",
    name: "Reports",
    key: "reports",
    icon: <Icon fontSize="medium">image</Icon>,
    collapse: [
      {
        name: "Events per journal",
        key: "events-per-journal",
        route: "/events-per-journal",
      },
    ],
  },
  { type: "divider", key: "divider-11111" },
  { type: "title", title: "Admin", key: "title-admin" },
  {
    type: "page",
    icon: <MDTypography>U</MDTypography>,
    name: "Users",
    key: "users",
    route: "/users",
    noCollapse: true,
  },
  {
    type: "page",
    icon: <Icon fontSize="medium">event_busy</Icon>,
    name: "Delete DOI",
    key: "delete-doi",
    route: "/delete-doi",
    noCollapse: true,
  },
  {
    type: "page",
    icon: <Icon fontSize="medium">privacy_tip</Icon>,
    name: "GDPR replace",
    key: "gdpr-replace",
    route: "/gdpr-replace",
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Manage",
    key: "manage",
    icon: <Icon fontSize="medium">upcoming</Icon>,
    collapse: [
      {
        name: "Journals",
        key: "journals",
        route: "/journals",
      },
      {
        name: "Journals Import",
        key: "journals-import",
        route: "/journals-import",
      },
      {
        name: "Event types",
        key: "event-types",
        route: "/event-types",
      },
    ],
  },
];

export default routes;
