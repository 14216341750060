import { itemsPerPage } from "constants";
import React, { useEffect, useState } from "react";
import { Card, Grid, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import WarningOutlineIcon from "@mui/icons-material/WarningAmberOutlined";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Spinner from "components/Spinner/Spinner";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "components/table/Table";
import Pagination from "components/pagination/Pagination";
import { showSuccessToast, showErrorToast } from "helpers/helpers";
import {
  prepareJournalsFileForImport,
  getJournalsFileDiffPreview,
  importFileWithDiffChanges,
} from "helpers/journals";
import NoContentMessage from "components/NoContentMessage/NoContentMessage";
import MDTypography from "components/MDTypography";
import JournalDiffPreviewModal from "./JournalsDiffPreviewModal";
import { journalsDiffListColumns } from "./columns";

const JournalsDiffList = ({ match, history }) => {
  const { uuid } = match.params;

  const [isFileStatusLoading, setIsFileStatusLoading] = useState(false);
  const [isFilePreviewLoading, setIsFilePreviewLoading] = useState(false);
  const [isFileImportLoading, setIsFileImportLoading] = useState(false);
  const [error, setError] = useState(false);

  const [fileStatus, setFileStatus] = useState(null);
  const [previewData, setPreviewData] = useState(null);
  const [journalPreviewGenerated, setJournalPreviewGenerated] = useState(false);
  const [selectedDiffPreview, setSelectedDiffPreview] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [offset, setOffset] = useState(1);

  const fetchJournalsFileForImport = async () => {
    setIsFileStatusLoading(true);
    try {
      const resp = await prepareJournalsFileForImport(uuid);
      setFileStatus(resp.data);
    } catch (e) {
      setError(true);
    } finally {
      setIsFileStatusLoading(false);
    }
  };

  useEffect(() => {
    fetchJournalsFileForImport();
  }, [uuid]);

  const loadFileDifferencePreview = async () => {
    setIsFilePreviewLoading(true);
    try {
      const resp = await getJournalsFileDiffPreview(itemsPerPage, offset);
      setPreviewData(resp.data.journal_diff);
      setJournalPreviewGenerated(true);
      if (resp.data.total) {
        setPageCount(Math.ceil(resp.data?.total / itemsPerPage));
      } else {
        setPageCount(1);
      }
    } catch (e) {
      setError(true);
    } finally {
      setIsFilePreviewLoading(false);
    }
  };

  useEffect(() => {
    if (previewData) {
      loadFileDifferencePreview();
    }
  }, [offset]);

  const handlePreparePreviewClick = () => {
    loadFileDifferencePreview();
  };

  const handleImport = async () => {
    setIsFileImportLoading(true);
    try {
      await importFileWithDiffChanges();
      showSuccessToast("Successfully imported");
      history.goBack();
    } catch (e) {
      showErrorToast("Something went wrong");
      setError(true);
    } finally {
      setIsFileImportLoading(false);
    }
  };

  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage);
    setOffset(selectedPage * itemsPerPage + 1);
  };

  const renderStatusCard = (data, title, icon, color) => (
    <Grid item md={6}>
      <Card>
        <MDBox px={2} py={1}>
          <MDTypography
            variant="button"
            fontWeight="medium"
            color="secondary"
            textTransform="capitalize"
          >
            {title}:
          </MDTypography>
        </MDBox>
        <MDBox px={2}>
          <List sx={{ maxHeight: 200, minHeight: 200, overflow: "auto" }}>
            {data?.map((x) => (
              <ListItem key={uuidv4()}>
                <ListItemIcon>
                  {React.cloneElement(icon, { color, fontSize: "medium" })}
                </ListItemIcon>
                <ListItemText secondary={x} />
              </ListItem>
            ))}
          </List>
        </MDBox>
      </Card>
    </Grid>
  );

  const renderFilePreviewButton = () => (
    <>
      {!previewData && !isFilePreviewLoading && (
        <MDButton
          variant="gradient"
          color="secondary"
          size="medium"
          onClick={handlePreparePreviewClick}
          disabled={isFilePreviewLoading}
        >
          Prepare file difference preview
        </MDButton>
      )}
      {isFilePreviewLoading && <Spinner />}
    </>
  );

  const renderImportButton = () => (
    <>
      {!isFileImportLoading && (
        <MDButton variant="gradient" color="primary" size="medium" onClick={handleImport}>
          Import
        </MDButton>
      )}
      {isFileImportLoading && <Spinner />}
    </>
  );

  const renderContent = () => (
    <>
      <Grid container spacing={2}>
        {renderStatusCard(fileStatus?.warnings, "Warnings", <WarningOutlineIcon />, "warning")}
        {renderStatusCard(fileStatus?.errors, "Errors", <ErrorOutlineIcon />, "error")}
      </Grid>

      <MDBox display="flex" justifyContent="center" mt={4}>
        {renderFilePreviewButton()}
      </MDBox>
      {!isFilePreviewLoading && !error && previewData?.length > 0 && (
        <>
          <DataTable
            data={previewData}
            columns={journalsDiffListColumns}
            param="doi"
            onRowClick={(doi) =>
              setSelectedDiffPreview(previewData.find((y) => y.doi === doi) ?? null)
            }
            hoverable
            cursorPointer
          />
          <Pagination
            handlePageClick={handlePageClick}
            pageCount={pageCount}
            currentPage={currentPage}
          />
        </>
      )}
      {!isFilePreviewLoading && !error && previewData?.length === 0 && (
        <div>
          <NoContentMessage message="No differences found" />
        </div>
      )}
      {journalPreviewGenerated && (
        <MDBox display="flex" justifyContent="center" style={{ marginTop: "20px" }}>
          {renderImportButton()}
        </MDBox>
      )}
    </>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <JournalDiffPreviewModal
        handleClose={() => setSelectedDiffPreview(null)}
        selectedDiffPreview={selectedDiffPreview}
      />
      <MDBox my={3} className="centered-box" justifyContent="center">
        {isFileStatusLoading && <Spinner />}
        {!isFileStatusLoading && error && <ErrorMessage />}
        {!isFileStatusLoading && !error && renderContent()}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default JournalsDiffList;
