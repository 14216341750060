import { toast } from "react-toastify";
import { defaultErrorMessage, defaultSuccessMessage, notificationsTime } from "../constants";

export const changeFormatDate = (date) =>
  date
    ? [date.getDate(), date.getMonth() + 1, date.getFullYear()]
      .map((date) => (date < 10 ? `0${date}` : date))
      .join("-")
    : "";

export const changeFormatTime = (date) => {
  const ampm = date.getHours() >= 12 ? "PM" : "AM";
  const hours = date.getHours() >= 12 ? date.getHours() - 12 : date.getHours();
  const minutes = `0${date.getMinutes()}`.slice(-2);
  return `${hours}:${minutes} ${ampm}`;
};

export const formatReqStartDate = (date) => {
  if (!date) {
    return null;
  }

  const dateGMT = new Date(date);

  if (Number.isNaN(dateGMT.getTime())) {
    console.error("Invalid date provided:", date);
    return null;
  }

  dateGMT.setHours(0, 0, 0, 0);
  return dateGMT.toISOString();
};

export const formatReqEndDate = (date) => {
  if (!date) {
    return null;
  }

  const dateGMT = new Date(date);

  if (Number.isNaN(dateGMT.getTime())) {
    console.error("Invalid date provided:", date);
    return null;
  }

  dateGMT.setHours(23, 59, 59, 999);
  return dateGMT.toISOString();
};

export const transformDate = (date, showTime = false) => {
  let res = null;
  if (date) {
    const created = new Date(date);
    res = `${changeFormatDate(created)} ${showTime ? changeFormatTime(created) : ""}`;
  }
  return res;
};

export const shortenTitle = (title, length) => {
  if (title.length > length) {
    return `${title.substring(0, length)}…`;
  }
  return title;
};

export const getAttribute = (data, attribute) => {
  const name = data.find((attr) => attr.Name === attribute);
  return name ? name.Value : "-";
};

export const decodeToken = (token) => {
  if (token) {
    const base64String = token.split(".")[1];
    const decodedString = atob(base64String);
    return JSON.parse(decodedString);
  }
  return null;
};

export const showSuccessToast = (successMessage = defaultSuccessMessage) =>
  toast.success(successMessage, {
    position: "top-right",
    autoClose: notificationsTime,
  });

export const showErrorToast = (errorMessage = defaultErrorMessage) =>
  toast.error(errorMessage, {
    position: "top-right",
    autoClose: notificationsTime,
  });

export function getItemFromLocalStorage(key) {
  const item = localStorage.getItem(key);
  if (item === null) {
    return null;
  }
  return item;
}

export function getParsedItemFromLocalStorage(key, defaultValue = {}) {
  const item = getItemFromLocalStorage(key);

  if (item === null) {
    return defaultValue;
  }

  try {
    const parsedItem = JSON.parse(item);
    return parsedItem;
  } catch (error) {
    console.error(`Error parsing JSON for item with key '${key}':`, error);
    localStorage.removeItem(key);
    return defaultValue;
  }
}

export function setItemToLocalStorage(key, value) {
  try {
    localStorage.setItem(key, value);
  } catch (error) {
    console.error(`Failed to set item with key '${key}' in localStorage:`, error);
  }
}

export const splitLongString = (str, length = 80) => {
  const result = [];
  for (let i = 0; i < str.length; i += length) {
    result.push(str.substring(i, i + length));
  }
  return result;
};
