export const token = `${process.env.REACT_APP_TOKEN}`;
export const URL = `${process.env.REACT_APP_API_URL}`;
export const AUTH_URL = `${process.env.REACT_APP_AUTH_API_URL}`;
export const ENVIRONMENT = `${process.env.REACT_APP_ENV}`; // development | acceptance | production

export const authorized = localStorage.getItem(token);
export const itemsPerPage = 50;
export const itemsPerJournalEventsPage = 15;
export const itemsTotal = 4;
export const emptyDataMessage = "No data";
export const notificationsTime = 3000;
export const LOCAL_SETTINGS_STORAGE_KEY = 'thiemeSettings';

export const EVENTS_FILTER_SETTINGS = 'events_filter';

export const EVENT_SOURCE_TYPES = {
    transaction_connector: 'transaction-connector',
    s1_connector: 's1-connector',
    em_connector: 'em-connector',
    jats_connector: 'jats-connector',
    status_connector: 'status-connector',
    censhare_connector: 'censhare-connector'
}

export const defaultErrorMessage = "Error, something went wrong";
export const defaultSuccessMessage = "Action completed successfully";