import { Dialog, DialogActions, DialogContent, DialogTitle, Tabs, Tab, Box } from "@mui/material";
import { useEffect, useState } from "react";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import MDButton from "components/MDButton";
import NoContentMessage from "components/NoContentMessage/NoContentMessage";
import Spinner from "components/Spinner/Spinner";
import { getEvent } from "helpers/events";
import JsonData from "layouts/pages/details/settings/components/JsonData";
import MDBox from "components/MDBox";

const BronzeEventReviewModal = ({ handleClose, selectedEventId }) => {
    const isOpen = !!selectedEventId;

    const [events, setEvents] = useState({ bronze: null });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchEvent = async () => {
            setIsLoading(true);
            try {
                const response = await getEvent(selectedEventId);
                setEvents({
                    bronze: response.data?.bronze,
                });
            } catch (e) {
                setError(true);
            } finally {
                setIsLoading(false);
            }
        };

        if (selectedEventId) {
            fetchEvent();
        }
    }, [selectedEventId]);

    return (
        <Dialog maxWidth="xl" fullWidth onClose={handleClose} open={isOpen}>
            <DialogTitle id="alert-dialog-title">Event Review</DialogTitle>

            <DialogContent dividers>
                <MDBox py={3}>
                    <Tabs
                        value={0}
                        centered
                        style={{ overflow: "visible" }}
                    >
                        <Tab label="Bronze event" />
                    </Tabs>
                    {isLoading && <Spinner />}
                    {error && <ErrorMessage message="An error occurred while fetching events." />}
                    {!events.bronze && !isLoading && (
                        <NoContentMessage message="Event not found" />
                    )}
                    {!isLoading && !error && (
                        <Box role="tabpanel">
                            {events.bronze && <JsonData data={events.bronze} />}
                        </Box>
                    )}
                </MDBox>
            </DialogContent>

            <DialogActions>
                <MDButton variant="gradient" color="info" size="medium" onClick={handleClose}>
                    Close
                </MDButton>
            </DialogActions>
        </Dialog>
    );
};

export default BronzeEventReviewModal;
