import { useState, useEffect } from "react";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import ConfiguratorRoot from "examples/Configurator/ConfiguratorRoot";
import { useMaterialUIController, setOpenConfigurator, setFilterDateRange } from "context";
import MDDatePicker from "components/MDDatePicker";
import {
  formatReqStartDate,
  formatReqEndDate,
  getParsedItemFromLocalStorage,
  setItemToLocalStorage,
} from "helpers/helpers";
import { validateSettingsSchema } from "helpers/schemas";
import { LOCAL_SETTINGS_STORAGE_KEY } from "../../constants";

function Configurator() {
  const [controller, dispatch] = useMaterialUIController();
  const { openConfigurator, darkMode, reportType } = controller;
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleCloseConfigurator = () => setOpenConfigurator(dispatch, false);
  const handleConfiguratorSubmit = (settings) => setFilterDateRange(dispatch, settings);

  const getStorageID = () => LOCAL_SETTINGS_STORAGE_KEY;

  useEffect(() => {
    const storageId = getStorageID();
    let localSettings = getParsedItemFromLocalStorage(storageId);
    const isLocalSettingsValid = validateSettingsSchema(localSettings);

    if (!isLocalSettingsValid) {
      setItemToLocalStorage(storageId, JSON.stringify({}));
      localSettings = {};
    }

    if (openConfigurator) {
      if (localSettings) {
        setStartDate(localSettings.startDate);
        setEndDate(localSettings.endDate);
      }
    }
  }, [openConfigurator, reportType]);

  const handleSubmit = () => {
    const thiemeSettings = {
      startDate,
      endDate,
    };
    setItemToLocalStorage(getStorageID(), JSON.stringify(thiemeSettings));
    handleConfiguratorSubmit(thiemeSettings);
    handleCloseConfigurator();
  };

  return (
    <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={4}
        pb={0.5}
        px={3}
      >
        <MDBox>
          <MDTypography variant="h5">Filter</MDTypography>
        </MDBox>

        <Icon
          sx={({ typography: { size }, palette: { dark, white } }) => ({
            fontSize: `${size.lg} !important`,
            color: darkMode ? white.main : dark.main,
            stroke: "currentColor",
            strokeWidth: "2px",
            cursor: "pointer",
            transform: "translateY(5px)",
          })}
          onClick={handleCloseConfigurator}
        >
          close
        </Icon>
      </MDBox>
      <MDBox pb={3} px={3}>
        <MDBox mt={1} mb={3} lineHeight={1}>
          <MDTypography variant="h6" mb={2}>
            Limit to:
          </MDTypography>

          <MDBox
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <MDTypography variant="button" color="text">
              Start-date:
            </MDTypography>
            <MDDatePicker
              value={startDate}
              input={{ placeholder: "Select a date" }}
              onChange={(_, newValue) => {
                setStartDate(formatReqStartDate(newValue));
              }}
            />
          </MDBox>

          <MDBox
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 1,
            }}
          >
            <MDTypography variant="button" color="text">
              End-date:
            </MDTypography>
            <MDDatePicker
              value={endDate}
              input={{ placeholder: "Select a date" }}
              onChange={(_, newValue) => {
                setEndDate(formatReqEndDate(newValue));
              }}
            />
          </MDBox>
        </MDBox>
        <Divider />

        <MDBox my={3} lineHeight={1}>
          <MDButton onClick={handleSubmit} type="submit" color="info" variant="contained" fullWidth>
            apply
          </MDButton>
        </MDBox>
      </MDBox>
    </ConfiguratorRoot>
  );
}

export default Configurator;
