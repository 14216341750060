import React, { useState, useEffect, useMemo } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Box, CardContent, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { getGraphData, getDashboardTracker } from "helpers/dashboard";
import LineChart from "examples/Charts/LineCharts/DefaultLineChart";
import Spinner from "components/Spinner/Spinner";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";

function Dashboard() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [graphData, setGraphData] = useState(null);
  const [dashboardData, setDashboardData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(false);
      try {
        const graphResponse = await getGraphData();
        const dashboardResponse = await getDashboardTracker();
        const mappedDashboardData = [
          { value: dashboardResponse.data?.last_journal_import, label: 'Last journal import' },
          { value: dashboardResponse.data?.last_bronze_events?.s1, label: 'S1' },
          { value: dashboardResponse.data?.last_bronze_events?.em, label: 'EM' },
          { value: dashboardResponse.data?.last_bronze_events?.ccc, label: 'CCC' },
          { value: dashboardResponse.data?.last_bronze_events?.censhare, label: 'CENSHARE' },
          { value: dashboardResponse.data?.last_bronze_events?.tc, label: 'TC' },
        ];
        setDashboardData(mappedDashboardData)
        setGraphData(graphResponse.data.events_per_week);
      } catch (e) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const renderEventsPerSourceConnector = () => {
    if (!dashboardData?.length) return null;

    return (
      <>
        {dashboardData.map(item => (
          <Grid item xs={12} sm={6} md={4} key={item?.label}>
            <Box p={2}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    {item?.label}
                  </Typography>
                  <Typography variant="body1">
                    {new Date(item?.value).toLocaleString()}
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          </Grid>
        ))}
      </>
    );
  }

  const lineChartData = useMemo(() => {
    if (!graphData) return { labels: [], datasets: [] };

    const keysInOrder = ["total", "s1", "em", "ccc", "censhare", "tc"];
    const colors = ["primary", "secondary", "success", "danger", "warning", "info", "light", "dark"];

    return {
      labels: graphData.map((data) => `Week ${data.week}`),
      datasets: keysInOrder.map((key, index) => ({
        label: key.toUpperCase(),
        color: colors[index % colors.length],
        data: graphData.map((data) => data[key] || 0),
      })),
    };
  }, [graphData]);

  if (loading) return <Spinner />;
  if (error) return <ErrorMessage />;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mb={3}>
          <Grid container spacing={3}>

            <Grid item xs={12} sm={12}>
              <Card>
                <MDBox p={2}>
                  <Grid container>{renderEventsPerSourceConnector()}</Grid>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mb={3}>
          <Grid item xs={12}>
            <LineChart title="Events per week" description="Last 10 weeks" chart={lineChartData} />
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
