import React, { useCallback } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { DialogTitle, Grid, Box } from '@mui/material';
import Spinner from 'components/Spinner/Spinner';
import JsonData from 'layouts/pages/details/settings/components/JsonData';
import MDButton from 'components/MDButton';
import { splitLongString } from 'helpers/helpers';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { EVENT_SOURCE_TYPES } from "../../constants";
import "./Modal.css";

function Modal({ isOpen, setIsOpen, loading, error, data, modalTitle, showRawData = false, content = null, showQueryStringParameters = false, isDisregardedOnly = false, size = "md", hideButtons = false, setSelectedMessage }) {
    const [fullWidth, setFullWidth] = React.useState(true);

    const handleClose = () => {
        setIsOpen(false);
        setSelectedMessage?.(null);
    };

    const filterRawData = data => {
        let jsonData = "No raw data";

        if (data?.raw) {
            try {
                jsonData = JSON.parse(data.raw);
            } catch (error) {
                console.error("Failed to parse JSON", error);
                jsonData = "Invalid JSON data";
            }
        }

        return <JsonData data={jsonData} />;
    };

    const filterJsonData = data => {
        const processItem = item => {
            const newItem = { ...item, raw: undefined };
            if (newItem.xml_data && typeof newItem.xml_data === "string") {
                newItem.xml_data = splitLongString(newItem.xml_data);
            }
            return newItem;
        };

        if (Array.isArray(data)) {
            return data.map(item => processItem(item));
        }
        return processItem(data);
    };

    const shouldShowQueryStringParams = (data) => (
        showQueryStringParameters && data?.raw && (
            (data?.disregarded &&
                (data?.source === EVENT_SOURCE_TYPES.s1_connector || data?.source === EVENT_SOURCE_TYPES.em_connector)) || isDisregardedOnly)
    );

    const getQueryStringParameters = useCallback(() => {
        if (!shouldShowQueryStringParams(data)) {
            return null;
        }
        const rawData = JSON.parse(data?.raw);
        return rawData?.event?.queryStringParameters;
    }, [data, showQueryStringParameters]);

    const queryStringParameters = getQueryStringParameters();
    return (
        <Dialog
            maxWidth={size}
            fullWidth={fullWidth}
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={isOpen}
        >
            {
                !showRawData &&
                <DialogTitle id="alert-dialog-title" ml={3}>
                    {modalTitle}
                </DialogTitle>
            }

            <DialogContent>
                {
                    loading ?
                        <Spinner />
                        :
                        error ?
                            <ErrorMessage />
                            :
                            content || (showRawData ?
                                <Grid container>
                                    <Grid item xs={12} md={6}>
                                        {queryStringParameters && (
                                            <>
                                                <DialogTitle id="alert-dialog-title" ml={3}>
                                                    Query string parameters
                                                </DialogTitle>
                                                <Box className='json-container'>
                                                    <JsonData data={queryStringParameters} />
                                                </Box>
                                            </>
                                        )}
                                        <Grid item>
                                            <DialogTitle id="alert-dialog-title" ml={3}>
                                                Raw data
                                            </DialogTitle>
                                            <Box className="json-container">
                                                {filterRawData(data)}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <DialogTitle id="alert-dialog-title" ml={3}>
                                            JSON data
                                        </DialogTitle>
                                        <Box className="json-container">
                                            <JsonData data={filterJsonData(data)} />
                                        </Box>
                                    </Grid>
                                </Grid>
                                :
                                <JsonData data={data} />)
                }
            </DialogContent>

            {
                !hideButtons &&
                <DialogActions>
                    <MDButton className="search_button" variant="gradient" color="info" size="medium" onClick={handleClose}>
                        Close
                    </MDButton>
                </DialogActions>
            }
        </Dialog>
    );
};


export default Modal;
