import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Checkbox,
  Grid,
  Typography,
  Card,
} from "@mui/material";
import { useState } from "react";
import MDButton from "components/MDButton";
import Spinner from "components/Spinner/Spinner";
import MDBox from "components/MDBox";
import { updateEventType } from "helpers/events";
import { showSuccessToast, showErrorToast } from "helpers/helpers";

const EventTypeEditModal = ({ handleClose, selectedEvent, refetch }) => {
  const isOpen = !!selectedEvent;
  const [isLoading, setIsLoading] = useState(false);
  const [enableAuthors, setEnableAuthors] = useState(selectedEvent?.enable_authors ?? false);
  const [enableManuscripts, setEnableManuscripts] = useState(
    selectedEvent?.enable_manuscript ?? false
  );
  const [enableReviewers, setEnableReviewers] = useState(selectedEvent?.enable_reviewers ?? false);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const objectToSubmit = {
        enable_authors: enableAuthors,
        enable_manuscript: enableManuscripts,
        enable_reviewers: enableReviewers,
      };
      await updateEventType(selectedEvent?.key, objectToSubmit);

      showSuccessToast("Event type successfully updated");
      refetch();
      handleClose();
    } catch (error) {
      showErrorToast("Failed to update event type");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog maxWidth="xl" fullWidth onClose={handleClose} open={isOpen}>
      <DialogTitle id="alert-dialog-title">Edit Event Type</DialogTitle>
      <DialogContent dividers>
        <MDBox py={1}>
          {isLoading && <Spinner />}
          {!isLoading && selectedEvent && (
            <MDBox py={0}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Card>
                    <MDBox p={2}>
                      <Typography variant="h6" mb={2}>
                        Event Details
                      </Typography>
                      <MDBox display="flex" flexDirection="column" gap={1}>
                        <Typography variant="subtitle2">
                          <strong>Type:</strong> {selectedEvent.type}
                        </Typography>
                        <Typography variant="subtitle2">
                          <strong>Subscription Type:</strong> {selectedEvent.subscription_type}
                        </Typography>
                        <Typography variant="subtitle2">
                          <strong>Subscription Name:</strong> {selectedEvent.subscription_name}
                        </Typography>
                        <Typography variant="subtitle2">
                          <strong>Decision Type:</strong> {selectedEvent.decision_type}
                        </Typography>
                        <Typography variant="subtitle2">
                          <strong>Document Status:</strong> {selectedEvent.document_status_name}
                        </Typography>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Card>
                    <MDBox p={2}>
                      <Typography variant="h6" mb={2}>
                        Enable
                      </Typography>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={enableAuthors}
                            onChange={(e) => setEnableAuthors(e.target.checked)}
                          />
                        }
                        label="Authors"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={enableReviewers}
                            onChange={(e) => setEnableReviewers(e.target.checked)}
                          />
                        }
                        label="Reviewers"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={enableManuscripts}
                            onChange={(e) => setEnableManuscripts(e.target.checked)}
                          />
                        }
                        label="Manuscript"
                      />
                    </MDBox>
                  </Card>
                </Grid>
              </Grid>
            </MDBox>
          )}
        </MDBox>
      </DialogContent>
      <DialogActions>
        <MDButton
          disabled={isLoading}
          variant="gradient"
          color="success"
          size="medium"
          onClick={handleSubmit}
        >
          Submit
        </MDButton>
        <MDButton
          disabled={isLoading}
          variant="gradient"
          color="info"
          size="medium"
          onClick={handleClose}
        >
          Close
        </MDButton>
      </DialogActions>
    </Dialog>
  );
};

export default EventTypeEditModal;
