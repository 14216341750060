import { transformDate } from "helpers/helpers";

export const journalFilesListColumns = [
  {
    Header: "File name",
    id: "file_name",
    Cell: ({ row }) => <span>{row.original.key ?? "-"}</span>,
  },
  {
    Header: "Last modified",
    id: "last_modified",
    Cell: ({ row }) => <span>{transformDate(row.original.last_modified, true)}</span>,
  },
];

export const journalsDiffListColumns = [
  {
    Header: "title",
    id: "title",
    Cell: ({ row }) => <span>{row.original.preview.journal_title ?? "-"}</span>,
  },
  {
    Header: "doi",
    id: "doi",
    Cell: ({ row }) => <span>{row.original.doi || "-"}</span>,
  },
  {
    Header: "journal_status",
    id: "journal_status",
    Cell: ({ row }) => <span>{row.original.preview.journal_status || "-"}</span>,
  },
];
