import React, { useState, useEffect } from "react";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "components/table/Table";
import Spinner from "components/Spinner/Spinner";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import NoContentMessage from "components/NoContentMessage/NoContentMessage";
import Pagination from "components/pagination/Pagination";
import { getEventTypes } from "helpers/events";
import { itemsPerPage, itemsTotal } from "../../constants";
import EventTypeEditModal from "./components/EventTypeEditModal";

function EventTypesList() {
  const [eventTypesList, seteEventTypesList] = useState([]);

  const [eventTypeItemToEdit, setEventTypeItemToEdit] = useState(null);
  const [loading, setLoading] = useState(false);

  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [offset, setOffset] = useState(1);

  const [error, setError] = useState(false);

  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage);
    setOffset(selectedPage * itemsPerPage + 1);
  };

  const CheckOrCloseIcon = ({ condition }) => (
    <span className="route_condition_label">
      {condition ? (
        <CheckIcon color="success" fontSize="small" />
      ) : (
        <CloseIcon color="error" fontSize="small" />
      )}
    </span>
  );

  const columns = [
    {
      Header: "Type",
      id: "Type",
      Cell: ({ row }) => <span>{row.original.type ?? "-"}</span>,
    },
    {
      Header: "Subscription type",
      id: "Subscription type",
      Cell: ({ row }) => <span>{row.original.subscription_type ?? "-"}</span>,
    },
    {
      Header: "Subscription name",
      id: "Subscription name",
      Cell: ({ row }) => <span>{row.original.subscription_name ?? "-"}</span>,
    },
    {
      Header: "Decision type",
      id: "Decision type",
      Cell: ({ row }) => <span>{row.original.decision_type ?? "-"}</span>,
    },
    {
      Header: "Document Status",
      id: "Status",
      Cell: ({ row }) => <span>{row.original.document_status_name ?? "-"}</span>,
    },
    {
      Header: "Authors",
      accessor: "enable_authors",
      Cell: ({ value }) => <CheckOrCloseIcon condition={value} />,
    },
    {
      Header: "Reviewers",
      accessor: "enable_reviewers",
      Cell: ({ value }) => <CheckOrCloseIcon condition={value} />,
    },
    {
      Header: "Manuscript",
      accessor: "enable_manuscript",
      Cell: ({ value }) => <CheckOrCloseIcon condition={value} />,
    },
  ];

  const fetchEventTypesList = async () => {
    setLoading(true);
    try {
      const response = await getEventTypes(offset, itemsPerPage);
      seteEventTypesList(response?.data?.eventTypes ?? []);
      setPageCount(Math.ceil((response.data.total || itemsTotal) / itemsPerPage));
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEventTypesList();
  }, [offset]);

  const handleEventTypeEdit = () => { };

  const handleModalClose = () => {
    setEventTypeItemToEdit(null);
  };

  const handleRowClick = (key) => {
    const eventToEdit = eventTypesList.find((x) => x.key === key);
    if (eventToEdit) {
      setEventTypeItemToEdit(eventToEdit);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {eventTypeItemToEdit && (
        <EventTypeEditModal
          handleClose={handleModalClose}
          selectedEvent={eventTypeItemToEdit}
          refetch={fetchEventTypesList}
        />
      )}

      <MDBox my={3}>
        {loading && <Spinner />}
        {!loading && error && <ErrorMessage />}
        {!loading && !error && eventTypesList.length > 0 && (
          <>
            <DataTable
              data={eventTypesList}
              columns={columns}
              param="key"
              onRowClick={handleRowClick}
              hoverable
              cursorPointer
            />
            <Pagination
              handlePageClick={handlePageClick}
              pageCount={pageCount}
              currentPage={currentPage}
              onRowClick={handleEventTypeEdit}
              param="key"
            />
          </>
        )}
        {!loading && !error && eventTypesList.length === 0 && (
          <NoContentMessage message="No events" />
        )}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EventTypesList;
