import { Dialog, DialogActions, DialogContent, DialogTitle, Tabs, Tab, Box } from "@mui/material";
import { useEffect, useState } from "react";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import MDButton from "components/MDButton";
import NoContentMessage from "components/NoContentMessage/NoContentMessage";
import Spinner from "components/Spinner/Spinner";
import { getEvent } from "helpers/events";
import JsonData from "layouts/pages/details/settings/components/JsonData";
import MDBox from "components/MDBox";
import { splitLongString } from "helpers/helpers";

const EventReviewModal = ({ handleClose, selectedEventId }) => {
  const isOpen = !!selectedEventId;

  const [events, setEvents] = useState({ silver: null, bronze: null });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const filterJsonData = data => {
    const processItem = item => {
      const newItem = { ...item, raw: undefined };
      if (newItem.xml_data && typeof newItem.xml_data === "string") {
        newItem.xml_data = splitLongString(newItem.xml_data);
      }
      return newItem;
    };

    if (Array.isArray(data)) {
      return data.map(item => processItem(item));
    }
    return processItem(data);
  };
  useEffect(() => {
    const fetchEvent = async () => {
      setIsLoading(true);
      try {
        const response = await getEvent(selectedEventId);
        setEvents({
          silver: response.data?.silver,
          bronze: response.data?.bronze,
        });
      } catch (e) {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    };

    if (selectedEventId) {
      fetchEvent();
    }
  }, [selectedEventId]);

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Dialog maxWidth="xl" fullWidth onClose={handleClose} open={isOpen}>
      <DialogTitle id="alert-dialog-title">Event Review</DialogTitle>

      <DialogContent dividers>
        <MDBox py={3}>
          <Tabs
            value={activeTab}
            onChange={handleChangeTab}
            centered
            style={{ overflow: "visible" }}
          >
            <Tab label="Silver event" />
            <Tab label="Bronze event" />
          </Tabs>
          {isLoading && <Spinner />}
          {error && <ErrorMessage message="An error occurred while fetching events." />}
          {!events.silver && !events.bronze && !isLoading && (
            <NoContentMessage message="Event not found" />
          )}
          {!isLoading && !error && (
            <>
              <Box role="tabpanel" hidden={activeTab !== 0}>
                {events.silver && <JsonData data={events.silver} />}
              </Box>
              <Box role="tabpanel" hidden={activeTab !== 1}>
                {events.bronze && <JsonData data={filterJsonData(events.bronze)} />}
              </Box>
            </>
          )}
        </MDBox>
      </DialogContent>

      <DialogActions>
        <MDButton variant="gradient" color="info" size="medium" onClick={handleClose}>
          Close
        </MDButton>
      </DialogActions>
    </Dialog>
  );
};

export default EventReviewModal;
