import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import MDBox from "components/MDBox";
import NoContentMessage from "components/NoContentMessage/NoContentMessage";
import Spinner from "components/Spinner/Spinner";
import DataTable from "components/table/Table";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { getFilesWithJournals } from "helpers/journals";
import JournalDiffPreviewModal from "./JournalsDiffPreviewModal";
import { journalFilesListColumns } from "./columns";

const JournalFilesList = ({ history }) => {
  const [journalFilesList, setJournalFilesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [selectedFileId, setSelectedFileId] = useState(null);

  useEffect(() => {
    const fetchJournalFilesList = async () => {
      setLoading(true);
      try {
        const response = await getFilesWithJournals();
        setJournalFilesList(response.data.files);
      } catch (e) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchJournalFilesList();
  }, []);

  const handleJournalFileReview = (uuid) => {
    setSelectedFileId(uuid);
    history.push(`journals-import-preview/${uuid}`);
  };

  const handleModalClose = () => {
    setSelectedFileId(null);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <JournalDiffPreviewModal handleClose={handleModalClose} selectedFileId={selectedFileId} />
      <Grid container>
        <Grid item md={8} my={3} classNa>
          <Typography variant="h6" component="h2" gutterBottom>
            Instructions for File Overview:
          </Typography>
          <Typography variant="body1" paragraph>
            To import a new version of the Journal Master List, upload it to the ESS SFTP server (/files/production/journals). Please make sure to remove old versions when they are no longer needed.
          </Typography>
        </Grid>
      </Grid>

      <MDBox my={3}>
        {loading ? <Spinner /> : null}
        {!loading && error ? <ErrorMessage /> : null}
        {!loading && !error && journalFilesList.length > 0 ? (
          <DataTable
            data={journalFilesList}
            columns={journalFilesListColumns}
            param="uuid"
            onRowClick={handleJournalFileReview}
            hoverable
            cursorPointer
          />
        ) : null}
        {!loading && !error && journalFilesList.length === 0 ? (
          <NoContentMessage message="No journal files" />
        ) : null}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default JournalFilesList;
