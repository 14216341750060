import axios from "axios";
import {AUTH_URL, token as TOKEN, URL} from "../constants";

const configureAxiosInstances = (urls) =>
    urls.map((url) => {
        const axiosInstance = axios.create({
            baseURL: url,
        });
        axiosInstance.interceptors.request.use((config) => {
            const customConfig = {...config};
            const token = localStorage.getItem(TOKEN);
            customConfig.headers.Authorization = `Bearer ${token}`;
            customConfig.headers["Content-Type"] = "application/json";
            return customConfig;
        });
        return axiosInstance;
    });

const [axiosInstance, axiosAuthInstance] = configureAxiosInstances([URL, AUTH_URL]);

function getAxiosInstanceWithTimeout(baseUrl, timeout) {
    const axiosInstance = axios.create({
        baseURL: baseUrl,
        timeout,
    });
    axiosInstance.interceptors.request.use((config) => {
        const customConfig = {...config};
        const token = localStorage.getItem(TOKEN);
        customConfig.headers.Authorization = `Bearer ${token}`;
        customConfig.headers["Content-Type"] = "application/json";
        return customConfig;
    });
    axiosInstance.interceptors.response.use(
        (response) => response,
        (error) => {
            if (axios.isCancel(error)) {
                // Request canceled
                // Handle or ignore this as needed
            } else if (error.code === 'ECONNABORTED') {
                // Timeout occurred, throw your custom error here
                const timeoutError = new Error('Request timeout');
                timeoutError.response = { ok: false, statusText: 'Request timeout' };
                throw timeoutError;
            } else {
                // Other errors
                throw error;
            }
        }
    );
    return axiosInstance;
}

const axiosInstanceWithTimeout = getAxiosInstanceWithTimeout(URL, 28 * 1000);

export {axiosInstance, axiosAuthInstance, axiosInstanceWithTimeout};
