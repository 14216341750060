import React from "react";
import "./Table.css";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

const searchTooltipTitle = "Basic search will only query identifiers (UUID, DOI, Manuscript ID, Submission ID). Enable Advanced Search to search all fields. Caution: Very generic queries in Advanced Search might return no results due to timeout.";

const SearchPanel = ({
  onSearch,
  searchValue,
  setSearchValue,
  noPadding = false,
  showAdvancedSearch = false,
  isAdvancedSearch,
  setIsAdvancedSearch,
}) => {
  const onSubmit = (event) => {
    event.preventDefault();
    onSearch();
  };

  const onInput = (event) => {
    setSearchValue(event.target.value);
  };

  const searchClassName = !noPadding ? "search_panel" : "search_panel p-0";

  const handleChange = (_, value) => {
    setIsAdvancedSearch(value);
  };

  return (
    <form className={searchClassName} onSubmit={onSubmit}>
      <MDInput placeholder="Search..." value={searchValue} onChange={onInput} size="small" />
      {showAdvancedSearch && (
        <FormGroup>
          <FormControlLabel
            style={{ marginLeft: "10px" }}
            control={<Checkbox value={isAdvancedSearch} onChange={handleChange} />}
            label="Advanced search"
          />
        </FormGroup>
      )}
      <MDButton
        className="search_button"
        variant="gradient"
        color="info"
        type="submit"
        size="small"
      >
        search
      </MDButton>

      <Tooltip title={searchTooltipTitle}>
        <IconButton>
          <HelpOutlineIcon />
        </IconButton>
      </Tooltip>
    </form>
  );
};

export default SearchPanel;
