import { token } from "../constants";

// eslint-disable-next-line import/prefer-default-export
export const handleErrors = (error) => {
  if (error.response?.status === 401) {
    localStorage.removeItem(token);
    window.location.reload(true);
  } else if (!error.response.ok) {
    throw Error(error.response.statusText);
  }
  return error;
};
