import React, { useCallback, useState } from "react";
import { Grid, Card, TextField, Checkbox } from "@mui/material";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Spinner from "components/Spinner/Spinner";
import { deleteEvents, getEventsByDOI } from "helpers/events";
import { showErrorToast, showSuccessToast, transformDate } from "helpers/helpers";
import DataTable from "components/table/Table";
import BronzeEventReviewModal from "./components/BronzeEventReviewModal";

function DeleteDOI() {
  const [doi, setDoi] = useState("");
  const [events, setEvents] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [checkedItems, setCheckedItems] = useState([]);
  const [selectedEventId, setSelectedEventId] = useState(null);

  const handleCheckboxChange = useCallback((id, eventType) => {
    setCheckedItems((prevCheckedItems) => {
      const exists = prevCheckedItems.some((item) => item.id === id);
      if (exists) {
        return prevCheckedItems.filter((item) => item.id !== id);
      }
      return [...prevCheckedItems, { id, event_type: eventType }];
    });
  }, []);

  const fetchEventsByDOI = async () => {
    setEvents(null);
    try {
      if (!doi) {
        return;
      }
      setIsLoading(true);

      const doiToBase64 = btoa(doi);
      const response = await getEventsByDOI(doiToBase64);
      setEvents(response.data.events ?? []);
    } catch (e) {
      showErrorToast("Event with this DOI does not exist");
    } finally {
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    setDoi("");
    setEvents(null);
    setCheckedItems([]);
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await deleteEvents(checkedItems);
      setCheckedItems([]);
      showSuccessToast();
      await fetchEventsByDOI();
    } catch (error) {
      showErrorToast();
    } finally {
      setIsLoading(false);
    }
  };

  const showFetchedEvent = events;
  const showSubmitButtons = events;


  const handleOpenModal = (eventId) => {
    setSelectedEventId(eventId);
  };

  const handleModalClose = () => {
    setSelectedEventId(null);
  };

  const columns = [
    {
      Header: "Delete",
      id: "is_checked",
      Cell: (params) => {
        // eslint-disable-next-line camelcase
        const { id, event_type } = params?.row?.original;
        return (
          <Checkbox
            key={id}
            checked={checkedItems.some((item) => item.id === id)}
            onChange={(event) => {
              event.stopPropagation();
              handleCheckboxChange(id, event_type);
            }}
            onClick={(event) => { event.stopPropagation() }}
          />
        );
      },
    },
    {
      Header: "DOI",
      id: "doi",
      Cell: ({ row }) => <span className="route_condition_label">{row.original.doi || ""}</span>,
    },
    {
      Header: "Event type",
      id: "event_type",
      Cell: ({ row }) => (
        <span className="route_condition_label">{row.original.event_type || ""}</span>
      ),
    },
    {
      Header: "Timestamp",
      id: "timestamp",
      Cell: ({ row }) => (
        <span className="route_condition_label">{transformDate(row.original.timestamp, true)}</span>
      ),
    },
  ];
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <BronzeEventReviewModal handleClose={handleModalClose} selectedEventId={selectedEventId} />
      <MDBox mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} md={6}>
            <Card>
              <MDBox p={3}>
                <Grid container alignItems="end">
                  <Grid xs={8} item md={8}>
                    <MDTypography variant="h6" gutterBottom>
                      Event DOI
                    </MDTypography>
                    <TextField
                      placeholder="Enter event DOI"
                      value={doi}
                      onChange={(e) => setDoi(e.target.value)}
                      sx={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <MDButton
                      onClick={fetchEventsByDOI}
                      variant="gradient"
                      color="info"
                      sx={{ ml: 2, mb: 0.5 }}
                    >
                      Load Event
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
              {showSubmitButtons && (
                <MDBox p={3} sx={{ display: "flex", justifyContent: "center" }}>
                  {checkedItems.length > 0 && (
                    <MDButton
                      onClick={handleSubmit}
                      variant="gradient"
                      color="secondary"
                      sx={{ mr: 4 }}
                    >
                      Confirm delete
                    </MDButton>
                  )}
                  <MDButton onClick={handleReset} variant="gradient" color="error">
                    Reset
                  </MDButton>
                </MDBox>
              )}
            </Card>
          </Grid>
          <Grid item md={12}>
            {isLoading && <Spinner />}
            {!isLoading && showFetchedEvent && (
              <Card>
                <MDBox p={2}>
                  <DataTable
                    param="id"
                    data={events}
                    columns={columns}
                    onRowClick={handleOpenModal}
                    hoverable
                    cursorPointer
                  />
                </MDBox>
              </Card>
            )}
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default DeleteDOI;
