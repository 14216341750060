import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import { ToastContainer } from "react-toastify";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "components/table/Table";
import Spinner from "components/Spinner/Spinner";
import Pagination from "components/pagination/Pagination";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import NoContentMessage from "components/NoContentMessage/NoContentMessage";
import { shortenTitle } from "helpers/helpers";
import StatusCell from "layouts/ecommerce/orders/order-list/components/StatusCell";
import { getEventsPerJournal } from "helpers/events";
import "./EventsPerJournalList.css";
import "react-toastify/dist/ReactToastify.css";
import { itemsPerJournalEventsPage, itemsTotal } from "../../constants";

function EventsPerJournalList() {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [offset, setOffset] = useState(1);
  const [sortBy, setSortBy] = useState("");
  const [sortDir, setSortDir] = useState("asc");
  const [error, setError] = useState(false);

  const renderCell = (event, item) => {
    const cellStatus = item[event] ? { icon: "done", color: "success" } : { icon: "close", color: "disabled" };

    return <StatusCell icon={cellStatus.icon} color={cellStatus.color} />;
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "journal",
        id: "journal",
        Cell: ({ row }) => (
          <span className="route_condition_label">
            {shortenTitle(row.original.journal, 50) || "-"}
          </span>
        ),
      },
      {
        Header: "em",
        id: "em",
        Cell: ({ row }) => renderCell("em", row.original),
      },
      {
        Header: "s1",
        id: "s1",
        Cell: ({ row }) => renderCell("s1", row.original),
      },
      {
        Header: "ccc",
        id: "ccc",
        Cell: ({ row }) => renderCell("ccc", row.original),
      },
      {
        Header: "censhare",
        id: "censhare",
        Cell: ({ row }) => renderCell("censhare", row.original),
      },
      {
        Header: "tc",
        id: "tc",
        Cell: ({ row }) => renderCell("tc", row.original),
      },
    ],
    []
  );

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        setLoading(true);

        const res = await getEventsPerJournal(offset, itemsPerJournalEventsPage, sortBy, sortDir);
        setEvents(res.data);

        const { total: newTotal } = res.data.length && res.data[0] ? res.data[0] : {};
        const total = newTotal || itemsTotal;

        setPageCount(Math.ceil(total / itemsPerJournalEventsPage));
      } catch (e) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, [offset, sortBy, sortDir]);

  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    const pageItems = selectedPage * itemsPerJournalEventsPage;
    setCurrentPage(selectedPage);
    setOffset(pageItems + 1);
  };

  function renderContent() {
    if (loading) {
      return <Spinner />;
    }

    if (error) {
      return <ErrorMessage />;
    }

    if (events.length > 0) {
      return (
        <Card>
          <div className="journal_events_table">
            <DataTable
              data={events}
              columns={columns}
              param="article_id"
              sortBy={sortBy}
              sortDir={sortDir}
              setSortBy={setSortBy}
              setSortDir={setSortDir}
            />
          </div>
          <Pagination
            handlePageClick={handlePageClick}
            pageCount={pageCount}
            currentPage={currentPage}
          />
        </Card>
      );
    }
    return <NoContentMessage message="No events" />;
  }

  return (
    <DashboardLayout>
      <ToastContainer />
      <DashboardNavbar breadcrumbsTitle="Events per journal" />
      <MDBox my={3}>{renderContent()}</MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EventsPerJournalList;
